import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { MobileLocationSelection } from "./component";
import {
  getAdultsCount,
  getApplyUserHotelPreferences,
  getChildren,
  getFromDate,
  getLocation,
  getPetsCount,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../../search/actions";
import {
  getHasUserSetHotelPreferences,
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityCurrency,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityHotelsOnSaleFilter,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityMinMaxPriceRange,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilitySortOption,
  getHotelAvailabilityStarRatingsFilter,
  getMapBound,
  getOpenDatesModal,
  getPremiumStaysLodgingsCount,
  getSearchLocationLabel,
  getShowPremiumStaysOnlyFilter,
  getUserHotelPreferences,
  getUserHotelPreferencesCallState,
  getViewHotelsNearLocation,
} from "../../reducer";
import {
  setAmenitiesFilter,
  setHotelAvailabilitySortOption,
  setHotelNameFilter,
  setOpenDatesModal,
  setStarRatingsFilter,
  setPremiumStaysOnly,
  setFreeCancelFilter,
  setViewHotelsNearLocation,
  fetchInitialHotelAvailability,
  setHotelsOnSaleFilter,
  setLoyaltyProgramsFilter,
  setPolicyFilter,
  setMaxPriceFilter,
} from "../../actions/actions";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";
import {
  getTravelWalletCredit,
  getTravelWalletOffers,
} from "../../../travel-wallet/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    searchLocation: getLocation(state),
    locationLabel: getSearchLocationLabel(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    roomsCount: getRoomsCount(state),
    petsCount: getPetsCount(state),
    amenities: getHotelAvailabilityAmenitiesFilter(state),
    starRatings: getHotelAvailabilityStarRatingsFilter(state),
    showPremiumStaysOnly: getShowPremiumStaysOnlyFilter(state),
    maxPrice: getHotelAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    currency: getHotelAvailabilityCurrency(state),
    hotelName: getHotelAvailabilityHotelNameFilter(state),
    sortOption: getHotelAvailabilitySortOption(state),
    dateModalOpen: getOpenDatesModal(state),
    hasFreeCancelFilter: getHotelAvailabilityFreeCancelFilter(state),
    hasHotelsOnSaleFilter: getHotelAvailabilityHotelsOnSaleFilter(state),
    isSearchingMap: !!getMapBound(state) || !!getViewHotelsNearLocation(state),
    loyaltyPrograms: getHotelAvailabilityLoyaltyProgramsFilter(state),
    isInPolicy: getHotelAvailabilityPolicyFilter(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHotelPreferences: getUserHotelPreferences(state),
    userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
    hasUserSetHotelPreferences: getHasUserSetHotelPreferences(state),
    premiumStaysLodgingCount: getPremiumStaysLodgingsCount(state),
    walletItemCount: getTravelWalletCredit(state)
      ? getTravelWalletOffers(state).length + 1
      : getTravelWalletOffers(state).length,
  };
};

const mapDispatchToProps = {
  setOccupancyCounts: actions.setOccupancyCounts,
  setRoomsCount: actions.setRoomsCount,
  setPetsCount: actions.setPetsCount,
  setAmenitiesFilter,
  setStarRatingsFilter,
  setPremiumStaysOnly,
  setMaxPriceFilter,
  setHotelNameFilter,
  setFreeCancelFilter,
  setHotelsOnSaleFilter,
  setHotelAvailabilitySortOption,
  setDateModalOpen: setOpenDatesModal,
  setViewHotelsNearLocation,
  fetchInitialHotelAvailability,
  setLoyaltyProgramsFilter,
  setPolicyFilter,
  setApplyUserHotelPreferences,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileLocationSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileLocationSelection = withRouter(
  connector(MobileLocationSelection)
);
